import { qpJoin } from "@constants";

export const gerRoute = (pathname: string) => {
    const partial = pathname.split("/");
    if (partial.length > 1) {
        return partial.slice(2).join("/");
    }
    return pathname;
};

export const buildQueryParams = (params: Record<string, string>) => {
    const queryParams = Object.entries(params)
        .filter(([_, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join(qpJoin);

    return queryParams ? `?${queryParams}` : "";
};


export const clearQP = (key: string) => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has(key)) {
        queryParams.delete(key);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.replaceState(null, "", newUrl);
    }
};